<template>
  <div id="addNonWorking" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container class="base">
      <v-row no-gutters class="titulo ml-10 mt-5">AGREGAR DÍA INHÁBIL</v-row>
      <v-container fluid class="addCategory2">
        <v-row no-gutters justify="end">
          <v-col cols="12" lg="5" md="5" sm="5" class="marginCol formularios">
            <v-row>
              <div class="form-group mt-n4">
                <label for="calendar">Empresa</label>
                <select name="empresa" id="empresa" v-model="enterprise">
                  <option disabled value="0" selected>
                    Selecciona Empresa
                  </option>
                  <option
                    v-for="enterprise in enterprises"
                    :key="enterprise.id"
                    v-bind:value="enterprise.id"
                  >
                    {{ enterprise.razonSocial }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="reason">Motivo del día:</label>
                <input
                  type="text"
                  name="reason"
                  id="reason"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="reason"
                  required
                  maxlength="35"
                  @keypress="alfaNumerico($event)"
                />
              </div>
              <div class="form-group mt-2">
                <label for="job">Oficial</label>
                <select name="job" id="job" v-model="oficial">
                  <option disabled value="0">¿Es oficial?</option>
                  <option value="1">Sí</option>
                  <option value="2">No</option>
                </select>
              </div>
              <div class="form-group">
                <label for="date">Fecha</label>
                <input
                  type="date"
                  name="date"
                  id="date"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="date"
                  required
                  maxlength="20"
                  :min="fecha_minimo"
                />
              </div>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            lg="6"
            md="6"
            sm="6"
            class="mt-5"
            align-self="center"
          >
            <v-row justify="center">
              <button class="botonAmarillo" @click="validacion()">
                Guardar
              </button>
              <button class="botonAmarilloDerecho" @click="regresar()">
                Regresar
              </button>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <!--Error-->
    <v-dialog v-model="error" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">{{ message }}</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-sm-center text-lg-center">
            {{ respuesta }}
          </h3>
          <br />
          <ul>
            <li v-for="error in errors" :key="error.name">{{ error }}</li>
          </ul>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="error = false">Corregir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Validación de datos-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">{{ message }}</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-lg-center text-sm-center">
            {{ respuesta }}
          </h3>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="cancelar()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="guardar()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">{{ message }}</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-lg-center text-sm-center text-md-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="aux()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      search: "",
      dialog: false,
      selectedItem: null,
      message: "",
      //dialogs
      errors: [],
      error: false,
      respuesta: "",
      confirmation: false,
      advice: false,
      day: 0,
      oficial: 0,
      reason: "",
      date: "",
      enterprise: localStorage.empresaIdGlobal,
      enterprises: [],
      fecha_minimo: "",
    };
  },
  methods: {
    alfaNumerico: function (evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]+$"); // Letras,espacio y números
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    validacion() {
      //console.log("ID DE LA EMPRESA ES: " + localStorage.idEmpresa);
      this.errors = [];

      this.message = "Día inhábil";
      if (this.enterprise == "" || this.enterprise == 0) {
        this.errors.push("Se debe especificar la empresa.");
      }
      if (this.reason == "" || this.reason == " ") {
        this.errors.push("Se debe especificar el motivo del día inhábil.");
      }
      if (this.oficial == 0) {
        this.errors.push("Se debe especificar sí es o no oficial.");
      }
      if (this.date == "") {
        this.errors.push("Se debe especificar la fecha.");
      }
      if (this.errors.length == 0) {
        this.respuesta = "Esta seguro de la asignación de día inhábil.";
        this.advice = true;
      } else {
        this.respuesta = "Por favor corrige el siguiente error:";
        this.error = true;
      }
    },
    regresar() {
      this.$router.push("/nonWorking");
    },
    cancelar() {
      (localStorage.idEmpresa = ""), (this.enterprise = 0);
      this.reason = "";
      this.oficial = "";
      this.date = "";
      this.advice = false;
    },
    guardar() {
      this.advice = false;
      this.show = true;
      axios
        .post(
          Server + "/diaInhabil",
          {
            EmpresaId: this.enterprise,
            Motivo: this.reason,
            Oficial: this.oficial == "1" ? true : false,
            fecha: this.date,
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          console.log(response);
          localStorage.idEmpresa = "";
          this.date = "";
          this.reason = "";
          this.oficial = 0;
          this.message = "Confirmación";
          this.respuesta = "El Día fue almacenado con éxito";
          this.show = false;
          this.confirmation = true;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            localStorage.idEmpresa = "";
            this.date = "";
            this.reason = "";
            this.oficial = 0;
            this.message = "Aviso";
            this.respuesta = "El día no fue almacenado.";
            this.confirmation = true;
          }
        });
    },
    aux() {
      this.$router.push("/nonWorking");
    },
    listar() {
      this.show = true;
      axios
        .get(Server + "/empresas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          // console.log(response);
          this.enterprises = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    fechasMinimo() {
      var fecha = new Date();
      var anio = fecha.getFullYear();
      var dia = fecha.getDate();
      var _mes = fecha.getMonth(); //0 al 11
      _mes = _mes + 1; //de 1 al 12
      if (_mes < 10) {
        //agrega un 0
        var mes = "0" + _mes;
      } else {
        var mes = _mes.toString();
      }
      this.fecha_minimo = anio + "-" + mes + "-" + dia; // Nueva variable
    },
  },
  created() {
    this.fechasMinimo();
    this.listar();
  },
};
</script>